// src/MobileSidePanel/utils/context.js
import { createContext } from "react";
var MobileSidePanelContext = createContext({
	isOpen: false,
	togglePanel: () => {},
	openPanel: () => {},
	closePanel: () => {}
});
var context_default = MobileSidePanelContext; // src/MobileSidePanel/utils/getContentStyles.js

var getContentStyles = ({
	breakpointModifier,
	menuPosition,
	animDuration,
	animFunction,
	isNear
}) => {
	const baseStyles = {
		padding: isNear ? "16px" : "48px 16px 24px",
		width: "100%",
		"overflow-x": "hidden",
		"overflow-y": "auto",
		"align-items": "center",
		"justify-content": "space-between",
		"flex-direction": "row",
		[`${breakpointModifier}width`]: "initial",
		[`${breakpointModifier}min-width`]: "280px",
		[`${breakpointModifier}min-height`]: "32px",
		[`${breakpointModifier}align-items`]: "flex-start",
		[`${breakpointModifier}flex-direction`]: "column",
		[`${breakpointModifier}box-shadow`]: "--xxl"
	};
	const animStyles = {
		appear: {
			open: {
				[`${breakpointModifier}transition`]: `
					visibility ${animDuration} step-start,
					opacity ${animDuration} ${animFunction}
				`,
				[`${breakpointModifier}visibility`]: "visible",
				[`${breakpointModifier}opacity`]: "1"
			},
			closed: {
				[`${breakpointModifier}transition`]: `
					visibility ${animDuration} step-end,
					opacity ${animDuration} ${animFunction}
				`,
				[`${breakpointModifier}visibility`]: "hidden",
				[`${breakpointModifier}opacity`]: "0"
			}
		},
		shift: {
			base: {
				[`${breakpointModifier}transition`]: `
					transform ${animDuration} ${animFunction}
				`
			}
		}
	};

	switch (menuPosition) {
		case "full":
			return {
				normal: { ...baseStyles,
					[`${breakpointModifier}top`]: "0",
					[`${breakpointModifier}left`]: "0",
					[`${breakpointModifier}width`]: "100%",
					[`${breakpointModifier}height`]: "100%",
					[`${breakpointModifier}position`]: "fixed"
				},
				open: animStyles.appear.open,
				closed: animStyles.appear.closed
			};

		case "left":
		case "right":
			return {
				normal: { ...baseStyles,
					[`${breakpointModifier}top`]: "0",
					[`${breakpointModifier}${menuPosition === "left" ? "right" : "left"}`]: "100%",
					[`${breakpointModifier}padding-top`]: "38px",
					[`${breakpointModifier}width`]: "280px",
					[`${breakpointModifier}height`]: "100%",
					[`${breakpointModifier}position`]: "fixed"
				},
				closed: { ...animStyles.shift.base,
					[`${breakpointModifier}transform`]: "translateX(0)"
				},
				open: { ...animStyles.shift.base,
					[`${breakpointModifier}transform`]: `translateX(${menuPosition === "left" ? "100%" : "-100%"})`
				}
			};

		case "near":
			return {
				normal: { ...baseStyles,
					[`${breakpointModifier}top`]: "100%",
					[`${breakpointModifier}right`]: "0",
					[`${breakpointModifier}position`]: "absolute"
				},
				open: animStyles.appear.open,
				closed: animStyles.appear.closed
			};

		case "nearRight":
			return {
				normal: { ...baseStyles,
					[`${breakpointModifier}top`]: "100%",
					[`${breakpointModifier}left`]: "0",
					[`${breakpointModifier}position`]: "absolute"
				},
				open: animStyles.appear.open,
				closed: animStyles.appear.closed
			};

		default:
			return {};
	}
};

var getContentStyles_default = getContentStyles; // src/MobileSidePanel/utils/getStyles.js

var getStyles = ({
	breakpoint,
	menuPosition,
	animDuration,
	animFunction,
	isNear
}) => {
	const breakpointModifier = breakpoint !== "all" ? `${breakpoint}-` : "";
	return {
		Button: {
			"padding-right": "3px",
			"min-height": "0",
			"align-items": "center",
			"flex-direction": menuPosition === "left" || menuPosition === "nearRight" ? "row-reverse" : "row",
			position: "relative",
			cursor: "pointer",
			display: "none",
			[`${breakpointModifier}display`]: "inline-flex",
			[`${breakpointModifier}flex`]: "0 0 auto",
			[`${breakpointModifier}z-index`]: isNear ? "2" : "1"
		},
		"Button Text": {
			margin: "0 .35em 0 0",
			"font-size": "14px",
			"user-select": "none"
		},
		Wrapper: {
			width: "100%",
			height: "100%",
			position: "relative",
			display: "flex",
			"z-index": "1",
			[`${breakpointModifier}top`]: "0",
			[`${breakpointModifier}left`]: "0",
			[`${breakpointModifier}position`]: isNear ? "absolute" : "fixed"
		},
		"Wrapper :open": {
			[`${breakpointModifier}transition`]: `visibility ${animDuration} step-start`,
			[`${breakpointModifier}visibility`]: "visible"
		},
		"Wrapper :closed": {
			[`${breakpointModifier}transition`]: `visibility ${animDuration} step-end`,
			[`${breakpointModifier}visibility`]: "hidden"
		},
		Overlay: {
			width: "100%",
			height: "100%",
			"background-color": isNear ? "transparent" : "rgba(0,0,0, .5)",
			position: "relative",
			display: "none",
			"z-index": "1",
			[`${breakpointModifier}top`]: "0",
			[`${breakpointModifier}left`]: "0",
			[`${breakpointModifier}position`]: "fixed",
			[`${breakpointModifier}display`]: "block"
		},
		"Overlay :open": {
			[`${breakpointModifier}transition`]: `
      visibility ${animDuration} step-start,
      opacity ${animDuration} ${animFunction}
    `,
			[`${breakpointModifier}visibility`]: "visible",
			[`${breakpointModifier}opacity`]: "1"
		},
		"Overlay :closed": {
			[`${breakpointModifier}transition`]: `
      visibility ${animDuration} step-end,
      opacity ${animDuration} ${animFunction}
    `,
			[`${breakpointModifier}visibility`]: "hidden",
			[`${breakpointModifier}opacity`]: "0"
		},
		Content: {
			"max-width": "100vw",
			"max-height": "100vh",
			"background-color": "white",
			"box-sizing": "border-box",
			display: "flex",
			"z-index": "2",
			...getContentStyles_default({
				breakpointModifier,
				menuPosition,
				animDuration,
				animFunction,
				isNear
			}).normal
		},
		"Content :open": { ...getContentStyles_default({
				breakpointModifier,
				menuPosition,
				animDuration,
				animFunction,
				isNear
			}).open
		},
		"Content :closed": { ...getContentStyles_default({
				breakpointModifier,
				menuPosition,
				animDuration,
				animFunction,
				isNear
			}).closed
		},
		Children: {
			width: "100%"
		},
		Cross: {
			top: "16px",
			right: "16px",
			position: "absolute",
			cursor: "pointer",
			display: "none",
			[`${breakpointModifier}display`]: isNear ? "none" : "block"
		}
	};
};

var getStyles_default = getStyles; // src/MobileSidePanel/MobileSidePanel.js

import React3, { useState, useMemo, useCallback, useEffect, useRef } from "react";
import { useOverrides } from "@quarkly/components";
import { Box, Text, Icon } from "@quarkly/widgets"; // src/MobileSidePanel/props/propsInfo.js

var propsInfo_default = {
	breakpoint: {
		title: {
			en: "Switch mobile view on breakpoint",
			ru: "Переключать мобильный вид на breakpoint"
		},
		control: "input",
		type: "text",
		variants: ["all", "sm", "md", "lg"],
		category: "Position",
		weight: 1
	},
	menuPosition: {
		title: {
			en: "Panel position in mobile view",
			ru: "Положение панели в мобильном виде"
		},
		control: "select",
		variants: [{
			title: {
				en: "Fullscreen",
				ru: "На весь экран"
			},
			value: "full"
		}, {
			title: {
				en: "To the left of the screen",
				ru: "По левому краю экрана"
			},
			value: "left"
		}, {
			title: {
				en: "To the right of the screen",
				ru: "По правому краю экрана"
			},
			value: "right"
		}, {
			title: {
				en: "To the left of the button",
				ru: "Слева от кнопки"
			},
			value: "near"
		}, {
			title: {
				en: "To the right of the button",
				ru: "Справа от кнопки"
			},
			value: "nearRight"
		}],
		type: "string",
		category: "Position",
		weight: 1
	},
	animDuration: {
		title: {
			en: "Show/hide animation duration",
			ru: "Длительность появления и скрытия"
		},
		control: "input",
		type: "text",
		variants: ["0s", "0.1s", "0.2s", "0.3s", "0.5s", "1s"],
		category: "Animation",
		weight: 1
	},
	animFunction: {
		title: {
			en: "Animation timing function",
			ru: "Функция сглаживания анимации"
		},
		control: "input",
		variants: ["linear", "ease", "ease-in", "ease-out", "ease-in-out", "step-start", "step-end"],
		type: "text",
		category: "Animation",
		weight: 1
	},
	onloadShow: {
		title: {
			en: "Show a panel when loading",
			ru: "Показать панель при загрузке"
		},
		control: "checkbox",
		category: "Test",
		weight: 1
	}
}; // src/MobileSidePanel/props/propsDefault.js

var propsDefault_default = {
	breakpoint: "md",
	menuPosition: "near",
	animDuration: ".3s",
	animFunction: "ease",
	onloadShow: false
}; // src/MobileSidePanel/props/overrides.js

import { FiMenu, FiX } from "react-icons/fi";
var iconProps = {
	normal: {
		category: "fi",
		defaultIcon: FiMenu,
		size: "24px",
		color: "--dark"
	},
	closed: {
		category: "fi",
		defaultIcon: FiMenu
	},
	open: {
		category: "fi",
		defaultIcon: FiX
	}
};
var overrides = {
	Button: {
		kind: "Box"
	},
	"Button Text": {
		kind: "Text",
		props: {
			children: "menu"
		}
	},
	"Button Icon": {
		kind: "Icon",
		props: iconProps.normal
	},
	"Button Icon :open": {
		kind: "Icon",
		props: iconProps.open
	},
	"Button Icon :closed": {
		kind: "Icon",
		props: iconProps.closed
	},
	Wrapper: {
		kind: "Box"
	},
	Overlay: {
		kind: "Box"
	},
	Content: {
		kind: "Box"
	},
	Children: {
		kind: "Box"
	},
	Cross: {
		kind: "Icon",
		props: iconProps.open
	}
};
var overrides_default = overrides; // src/ComponentNotice.js

import React from "react";
import atomize from "@quarkly/atomize";
var Message = atomize.div();

var ComponentNotice = ({
	message,
	...props
}) => <Message
	padding="16px"
	width="100%"
	font="--font-base"
	font-style="italic"
	color="--color-grey"
	background-color="--color-light"
	border="1px dashed --color-lightD2"
	box-sizing="border-box"
	{...props}
>
	{message || "Some Text"}
</Message>;

var ComponentNotice_default = atomize(ComponentNotice)({
	name: "Component Notice",
	propInfo: {
		message: {
			title: "Message",
			control: "input",
			type: "text",
			weight: 1
		}
	}
}); // src/utils/isEmptyChildren.js

import React2 from "react";

var isPlaceholder = child => child && child.props && child.props.text === "child placeholder";

var isOverride = child => child && child.props && typeof child.props.slot === "string" && child.props.slot.length > 0;

function isEmptyChildren(children) {
	const childrenArray = React2.Children.toArray(children);
	return !childrenArray.some(child => child && !isPlaceholder(child) && !isOverride(child));
} // src/utils/pick.js


var pick = (obj, ...keys) => keys.reduce((result, key) => {
	result[key] = obj[key];
	return result;
}, {});

var pick_default = pick; // src/utils/toggleScroll.js

var prevBodyStyles = {};

var disable = () => {
	const {
		body
	} = window.document;
	const scrollWidth = window.innerWidth - document.documentElement.clientWidth;
	prevBodyStyles = pick_default(body.style, "position", "left", "top", "right");
	Object.assign(body.style, {
		position: "fixed",
		left: "0",
		top: `-${window.scrollY}px`,
		right: `${scrollWidth}px`
	});
};

var enable = () => {
	const {
		body
	} = window.document;
	const scrollY = body.style.top;
	Object.assign(body.style, prevBodyStyles);
	window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
};

var toggleScroll_default = {
	disable,
	enable
}; // src/MobileSidePanel/MobileSidePanel.js

var MobileSidePanel = ({
	breakpoint,
	menuPosition,
	animDuration,
	animFunction,
	onloadShow,
	...props
}) => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides_default);
	const [isOpen, setOpen] = useState(onloadShow);
	const isNear = useMemo(() => menuPosition === "near" || menuPosition === "nearRight", [menuPosition]);
	const contentRef = useRef();
	const isEmpty = useMemo(() => isEmptyChildren(children), [children]);
	const openPanel = useCallback(() => {
		contentRef.current.scrollTo(0, 0);
		toggleScroll_default.disable(contentRef.current);
		setOpen(true);
	}, []);
	const closePanel = useCallback(() => {
		toggleScroll_default.enable(contentRef.current);
		setOpen(false);
	}, []);
	const togglePanel = useCallback(() => {
		!isOpen ? openPanel() : closePanel();
	}, [isOpen, openPanel, closePanel]);
	useEffect(() => {
		setOpen(isOpen || isEmpty);
	}, [setOpen, isOpen, isEmpty]);
	useEffect(() => {
		setOpen(onloadShow);
	}, [onloadShow]);
	useEffect(() => {
		return () => {
			closePanel();
		};
	}, [closePanel]);
	const styles = useMemo(() => getStyles_default({
		breakpoint,
		menuPosition,
		animDuration,
		animFunction,
		isNear
	}), [breakpoint, menuPosition, animDuration, animFunction, isNear]);
	const statusOpen = isOpen || isEmpty ? ":open" : ":closed";
	const statusButtopenPanel = isNear && (isOpen || isEmpty) ? ":open" : ":closed";
	const context = useMemo(() => ({
		isOpen,
		togglePanel,
		openPanel,
		closePanel
	}), [isOpen, togglePanel, openPanel, closePanel]);
	const handleClosePopup = useCallback(e => {
		if (e.target.closest("a")) {
			const a = e.target;
			const {
				pathname,
				protocol,
				host
			} = window.location;
			const isSame = a.pathname === pathname && a.protocol === protocol && a.host === host;

			if (isSame) {
				closePanel();
			}
		}
	}, []);
	return <Box
		flex="1 1 auto"
		width="100%"
		min-width="0px"
		min-height="0px"
		align-items="center"
		justify-content={menuPosition === "left" || menuPosition === "nearRight" ? "flex-start" : "flex-end"}
		position="relative"
		display="flex"
		z-index="5"
		{...rest}
	>
		    
		<Box onClick={isNear ? togglePanel : openPanel} {...styles.Button} {...override("Button", `Button ${statusButtopenPanel}`)}>
			      
			<Text {...styles["Button Text"]} {...override("Button Text", `Button Text ${statusButtopenPanel}`)} />
			      
			<Icon {...override("Button Icon", `Button Icon ${statusButtopenPanel}`)} />
			    
		</Box>
		    
		<Box {...styles.Wrapper} {...styles[`Wrapper ${statusOpen}`]} {...override("Wrapper", `Wrapper ${statusOpen}`)}>
			      
			<Box onClick={closePanel} {...styles.Overlay} {...styles[`Overlay ${statusOpen}`]} {...override("Overlay", `Overlay ${statusOpen}`)} />
			      
			<Box {...styles.Content} {...styles[`Content ${statusOpen}`]} {...override("Content", `Content ${statusOpen}`)} ref={contentRef}>
				        
				<Icon onClick={closePanel} {...styles.Cross} {...override("Cross")} />
				        
				<context_default.Provider value={context}>
					<Box {...styles.Children} {...override("Children", `Children ${statusOpen}`)} display={isEmpty ? "none" : void 0} onClick={handleClosePopup}>
						{children}
					</Box>
				</context_default.Provider>
				        
				{isEmpty && <ComponentNotice_default message="Drag any component here" />}
				      
			</Box>
			    
		</Box>
		  
	</Box>;
};

Object.assign(MobileSidePanel, {
	title: "Mobile side panel",
	description: {
		en: "Container to hide menu or any other content in the mobile version of the site",
		ru: "Контейнер для скрытия меню или любого другого контента в мобильной версии сайта"
	},
	propInfo: propsInfo_default,
	defaultProps: propsDefault_default,
	overrides: overrides_default
});
var MobileSidePanel_default = MobileSidePanel;
export { context_default as MobileSidePanelContext, MobileSidePanel_default as default };