import React, { useState } from "react";
import { useOverrides } from '@quarkly/components';
import { Box, Image } from '@quarkly/widgets'; // import Video from '@quarkly/community-kit/Video';

import atomize from "@quarkly/atomize";
const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(shorts\/)|(watch\?))\??v?=?([^#&?]*).*/;

const youtubeParser = url => {
	var match = url.match(regExp);

	if (!match || !match[8]) {
		return false;
	}

	return {
		id: match[8],
		type: match[6] === 'shorts/' ? 'shorts' : 'watch'
	};
};

const generateUrl = (id, params) => {
	const defaultParams = {
		rel: 0,
		showinfo: 0,
		start: 0,
		autoplay: true,
		loop: false // playlist: ""

	};
	const p = Object.assign(defaultParams, params);

	if (p.loop) {
		p.playlist = id;
	}

	Object.keys(p).forEach(key => p[key] === undefined && delete p[key]);
	const q = new URLSearchParams(p).toString();
	return `https://www.youtube.com/embed/${id}/?${q}`;
};

const Wrapper = atomize.div();
const Container = atomize.div({
	effects: {
		YThover: ":hover > div > *"
	}
});
const Button = atomize.button(); // const Image = atomize.img();

const VideoIframe = atomize.iframe();
const Video = atomize.video();
const YoutubeButton = atomize(() => {
	return <Button
		bgc="transparent"
		pos="absolute"
		t="50%"
		l="50%"
		w="68"
		h="48"
		bd="none"
		p="0"
		trf="translate(-50%, -50%)"
		aria-label="Start video"
		ol="none"
	>
		      
		<svg width="68" height="48" viewBox="0 0 68 48">
			        
			<path fillOpacity="0.8" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" />
			        
			<path fill="#fff" d="M 45,24 27,14 27,34" />
			      
		</svg>
		    
	</Button>;
})({
	effects: {
		hover: ":hover"
	}
});
const defaultProps = {
	// default props
	src: "https://www.youtube.com/watch?v=mBw3qzf4s18",
	alt: "My video",
	variant: "Fast and low traffic (recomended)",
	start: "0",
	loop: false,
	autoplay: false,
	"border-image-width": 0
};
const overrides = {
	'Source': {
		kind: 'Source',
		props: {
			typePicture: 'image/webp'
		}
	},
	'Image': {
		kind: 'Image',
		props: {
			'object-fit': 'cover',
			position: "absolute",
			height: "100%",
			width: "100%",
			top: 0,
			left: 0,
			border: "none"
		}
	},
	'Video': {
		kind: 'Video',
		props: {
			controls: true,
			autoPlay: true
		}
	}
};

const YTVideo = ({
	src,
	alt,
	variant,
	autoplay,
	loop,
	start,
	end,
	...props
}) => {
	const {
		children,
		override,
		ChildPlaceholder,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const {
		id,
		type
	} = youtubeParser(src) ?? {
		id: false
	};
	const [enabled, setEnabled] = useState(variant === "Standard" || autoplay); // if (!id) return <div {...props}>Error: Youtube link is not correct</div>;

	const videomedia = {
		position: "absolute",
		height: "100%",
		width: "100%",
		top: 0,
		left: 0,
		border: "none"
	};
	return <Wrapper {...rest}>
		      
		<Container
			position="relative"
			width="100%"
			height="0"
			padding-bottom={type == "shorts" ? '177.9510022271715%' : '56.25%'}
			onClick={e => {
				e.stopPropagation();
				setEnabled(true);
			}} //

			YThover-transform="scale(1.2)"
		>
			        
			{enabled ? id ? <VideoIframe {...videomedia} src={generateUrl(id, {
				autoplay: variant !== "Standard" || autoplay,
				start,
				end,
				loop
			})} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen /> : <Video alt={alt} src={src} loop={loop} {...override('Video')} /> : <>
				            
				<Image src={`https://i.ytimg.com/vi/${id}/maxresdefault.jpg`} {...override('Image')} />
				            
				<Box {...override('Box')}>
					              
					<ChildPlaceholder slot="Box" />
					            
				</Box>
				          
			</>}
			      
		</Container>
		    
	</Wrapper>;
};

export default atomize(YTVideo)({
	effects: {
		hover: ":hover"
	},
	description: {
		en: "Display video from youtube"
	},
	propInfo: {
		src: {
			description: {
				en: "Youtube video source"
			},
			control: "input",
			category: "Video",
			weight: 1
		},
		alt: {
			description: {
				en: "Alternative name"
			},
			control: "input",
			category: "Video",
			weight: 1
		},
		variant: {
			description: {
				en: "Variant of player"
			},
			control: "select",
			category: "Player",
			variants: ["Fast and low traffic (recomended)", "Standard"],
			weight: 1
		},
		loop: {
			description: {
				en: "Loop"
			},
			category: "Video",
			control: "checkbox"
		},
		autoplay: {
			description: {
				en: "Autoplay"
			},
			category: "Video",
			control: "checkbox"
		},
		start: {
			description: {
				en: "Start from"
			},
			category: "Video",
			control: "input"
		},
		end: {
			description: {
				en: "End"
			},
			category: "Video",
			control: "input"
		}
	}
}, // configuration
defaultProps);