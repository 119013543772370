import React from 'react';
import atomize from "@quarkly/atomize";

const PlayIcon = props => <svg viewBox="0 0 60 60" {...props}>
	      
	<g stroke="none" stroke-width="1" fill="" fill-rule="evenodd">
		        
		<g fill="#fff" transform="translate(-691.000000, -3514.000000)" fill="#FFFFFF">
			          
			<path d="M721,3574 C737.568542,3574 751,3560.56854 751,3544 C751,3527.43146 737.568542,3514 721,3514 C704.431458,3514 691,3527.43146 691,3544 C691,3560.56854 704.431458,3574 721,3574 Z M715,3534 L732,3544.5 L715,3555 L715,3534 Z"></path>
			        
		</g>
		      
	</g>
	    
</svg>;

export default atomize(PlayIcon)({
	name: "PlayIcon",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		// paste here description for your component
		en: "PlayIcon — my awesome component"
	},
	propInfo: {
		// paste here props description for your component
		yourCustomProps: {
			control: "input"
		}
	}
});